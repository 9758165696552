import { snakeCase } from 'lodash'
import { toValue } from 'vue'
import { useCurrentCompany } from '@/modules/base/composable/useCurrentCompany'

export const apiPrefix = '/api/v0'
export const enum Endpoint {
  Ability = 'ability',
  Assessments = 'assessments',
  AuditEvents = 'audit_events',
  Banners = 'banners',
  BannersCurrent = 'current',
  BannersInteractions = 'interactions',
  Companies = 'companies',
  Company = 'company',
  CompanyActions = 'company_actions',
  ConversionInfo = 'conversion_info',
  Countries = 'constants/countries',
  Dashboard = 'dashboard',
  DashboardReductionTargets = 'reduction_targets',
  Documents = 'documents',
  Download = 'download',
  EmissionCalculator = 'emission_calculator',
  EmissionCalculatorValues = 'values',
  EmissionSources = 'emission_sources',
  Entries = 'entries',
  FileUploadConstraints = 'constants/file_upload_constraints',
  FootprintActivities = 'footprint_activities',
  FootprintActivitiesMeta = 'meta',
  FootprintDataRooms = 'data_rooms',
  FootprintDataRoomsStatusChanges = 'status_changes',
  FootprintDataPointEmissionSourceInfo = 'info',
  FootprintDataPointEmissionSources = 'emission_sources',
  FootprintDataPoints = 'data_points',
  FootprintDataPointsSetDueRule = 'set_due_rule',
  FootprintDataPointValues = 'values',
  FootprintDataPointValuesComments = 'comments',
  FootprintDataPointValuesStatusChanges = 'status_changes',
  FootprintResults = 'footprint_results',
  Footprints = 'footprints',
  FootprintsBulkAssignments = 'bulk_assignment',
  FootprintsYears = 'years',
  FootprintTransfer = 'transfer',
  IndividualReductions = 'individual_reductions',
  Industries = 'constants/industries',
  Languages = 'languages',
  ListCompanies = 'list_companies',
  Locations = 'locations',
  Materiality = 'materiality',
  TaskBoards = 'task_boards',
  TaskBoardsMeta = 'task_boards/meta',
  Notes = 'notes',
  Onboarding = 'onboarding',
  Password = 'password',
  Proofs = 'proofs',
  ReductionRatios = 'reduction_ratios',
  Reporting = 'reporting',
  StatusBoard = 'status_boards',
  StatusBoardMeta = 'status_boards/meta',
  Subtasks = 'subtasks',
  Tags = 'tags',
  Topics = 'topics',
  Units = 'units',
  Users = 'users',
  Valeri = 'valeri',
  ValeriRows = 'rows',
  ValeriValues = 'values',
  YearlyEmissionsStatistics = 'yearly_emissions_statistics',
  Roles = 'roles',
}

/**
 * Connects all paths parts to create one url
 * @deprecated This utility is unstable and will be removed in the future. Use useUrl from `@/api/composable/useUrl` instead
 * @description Will automatically add api prefix /api/v0, use it only for paths that does not require companies/:id endpoint in front of the path
 * @param paths Pass all path parts without /
 * @returns Composed url
 */
export const createUrl = (...pathElements: unknown[]): string => {
  const unrefedPathElements = pathElements.map((element) => toValue(element))
  if (unrefedPathElements.some((element) => element === null)) {
    throw new Error(`Path element is null. Found in ${pathElements.join('/')}}`)
  }
  const predicateRecordType = (element: unknown) =>
    typeof element === 'object' && element !== null

  const queryParams = unrefedPathElements
    .filter(predicateRecordType)
    .flatMap((object) =>
      Object.entries(object as object)
        .filter((entry) => entry[1] !== undefined)
        .map((entry) => [snakeCase(entry[0]), entry[1]])
    )

  const queryString = new URLSearchParams(queryParams).toString()
  const path = unrefedPathElements.filter(
    (element) => !predicateRecordType(element)
  )

  return [apiPrefix, ...path].join('/') + (queryString ? '?' + queryString : '')
}

/**
 * @deprecated Use useUrl from `@/api/composable/useUrl` instead
 */
export const useUrl = () => {
  const { currentCompanyId } = useCurrentCompany()

  const withCompanyId = (url: readonly unknown[]): readonly unknown[] => [
    Endpoint.Companies,
    currentCompanyId,
    ...url,
  ]

  const createUrlWithCompanyId = (...url: unknown[]) =>
    createUrl(...withCompanyId(url))

  return {
    createUrl,
    withCompanyId,
    createUrlWithCompanyId,
  }
}
